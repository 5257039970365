var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column py-4"},[_c('div',{staticClass:"header-top"},[_c('h2',{staticClass:"view-title"},[_vm._v("Account Users")]),_c('v-text-field',{staticClass:"search",attrs:{"label":"Search","prepend-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search.search),callback:function ($$v) {_vm.$set(_vm.search, "search", $$v)},expression:"search.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"id","sort-desc":_vm.sort_desc,"footer-props":{ 'items-per-page-options': _vm.items_per_page },"options":_vm.options,"server-items-length":_vm.total,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(item.profile.avatar)?_c('img',{staticClass:"avatar",attrs:{"src":item.profile.avatar,"alt":item.first_name}}):_c('img',{staticClass:"avatar",attrs:{"src":_vm.images.avatarImg,"alt":item.first_name}})]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.first_name)+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.last_name)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-view"},_vm._l((item.roles),function(role){return _c('span',{key:role.id,staticClass:"role"},[_vm._v(_vm._s(role.name))])}),0)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cont-actions"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.goToPath('/account/user/' + item.id)}}},[_vm._v(" Detail ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }