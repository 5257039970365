<template>
  <div class="d-flex flex-column py-4">
    <div class="header-top">
      <h2 class="view-title">Account Users</h2>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      :sort-desc="sort_desc"
      :footer-props="{ 'items-per-page-options': items_per_page }"
      :options.sync="options"
      :server-items-length="total"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <img v-if="item.profile.avatar" class="avatar" :src="item.profile.avatar" :alt="item.first_name" />
        <img v-else class="avatar" :src="images.avatarImg" :alt="item.first_name" />
      </template>
      <template v-slot:[`item.first_name`]="{ item }">
        {{ item.profile.first_name }}
      </template>
      <template v-slot:[`item.last_name`]="{ item }">
        {{ item.profile.last_name }}
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <div class="col-view">
          <span class="role" v-for="role in item.roles" :key="role.id">{{ role.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="cont-actions">
          <v-btn color="primary" @click="goToPath('/account/user/' + item.id)" outlined> Detail </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import avatarImg from '@/assets/images/avatars/1.png'

export default {
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        { text: 'Photo', value: 'avatar', sortable: false },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'First Name', value: 'profile.first_name', sortable: true },
        { text: 'Last Name', value: 'last_name', sortable: true },
        { text: 'Role', value: 'role', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items_per_page: [5, 10, 25, 50, 100],
      sort_desc: true,
      options: {},
      errors: null,
      search: {
        search: '',
      },

      images: {
        avatarImg,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    ...mapActions({
      getClientUsers: 'client/getClientUsers',
      setSnackbar: 'snackbar/set',
    }),
    async getData() {
      this.loading = true
      await this.getClientUsers(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data.items
            this.total = resp.data.total
          }
        })
        .catch(error => {
          console.log(error)
          this.errors = error.response.data.error
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment(date).fromNow()
    },
    clearSearch() {
      this.search.search = ''
    },
    showDialog(item) {
      this.dialog_delete.show = true
      this.dialog_delete.item = item
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    goToPath(path) {
      this.$router.push({ path })
    },
  },
}
</script>

<style lang="css" scoped>
.col-view {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.role {
  /* padding: 5px 10px; */
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.v-data-table >>> td {
  min-width: 120px;
}
.avatar {
  max-width: 120px;
  max-height: 120px;
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>

